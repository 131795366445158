import isUndefined from 'lodash/isUndefined';
import isArray from 'lodash/isArray';
import axios, { addInterceptors } from '../interceptor-axios';
import config from '~/config';
import { SERVICE_TYPES } from '~/utils/constants';

// TODO: Change to arrow functions and remove [api.data, api.data.someFn] in saga
export default class ApiSearch {
  constructor(token) {
    const headers = {};
    if (token) {
      headers.Authorization = token;
    }
    this.axios = axios.create({
      baseURL: config.apiUrl.getAPISearchByService(),
      headers,
    });
    addInterceptors(this.axios);
  }

  searchData = (params) => {
    const serviceType = params.serviceType || config.serviceType;
    let request;
    let modelRealeasParam = {};
    if (params.modelRelease) {
      switch (params.modelRelease) {
        case '0':
          modelRealeasParam = { model: '2', property: '0' };
          break;
        case '1':
          modelRealeasParam = { model: '0', property: '2' };
          break;
        case '2':
          modelRealeasParam = { model: '0', property: '0' };
          break;
        default:
          return {};
      }
    }
    if (!params.image_token) {
      // Search by text
      if (
        serviceType === config.serviceType &&
        params.category_id &&
        isArray(params.category_id)
      ) {
        params.category_id = params.category_id.join(',');
      } else {
        params.category_id = params.category || '';
      }

      if (
        serviceType === config.serviceType &&
        params.excludeCategory_id &&
        isArray(params.excludeCategory_id)
      ) {
        params.excludeCategory_id = params.excludeCategory_id.join(',');
      } else {
        params.excludeCategory_id = params.excludeCategory || '';
      }

      if (params.modelAge && isArray(params.modelAge)) {
        params.modelAge = params.modelAge.join(',');
      }

      let controlParams = {};

      if (serviceType === SERVICE_TYPES.PHOTO_AC) {
        controlParams = {
          option: 2,
          offset: '25d',
          scale: '90d',
          decay: 0.75,
          download: 2,
          download_all: 0.54,
          download_weight: 0,
          weight: 1.42,
          factor_tag: 1.8,
          factor_localize: 0,
          rating: 0.2,
          rating_user: 0,
          log: 1,
          minscore: 0,
          ai_point_weight: 1,
          similar_rank_weight: 12,
          // nonested: true,
          boost_full_tag: 1,
          exclude_ai: false,
          disp_flg_global: true,
          site: 1,
        };
      } else if (serviceType === SERVICE_TYPES.ILLUST_AC) {
        controlParams = {
          rate: params.rate || 0.56,
          rand: isUndefined(params.rand) ? true : params.rand === 'true',
          pow: params.pow || 1.678,
          param: params.param || 14000,
          p5: params.p5 || 0.9,
          creatorName: params.creatorName,

          option: 2,
          offset: '7d',
          scale: '75d',
          decay: 0.7,
          download: 2,
          download_all: 0.28,
          weight: 3.4,
          rating: 1,
          factor_tag: 1.3,
          factor_localize: 0,
          log: 1,
          minscore: 0.04,
          // nonested: true,
          is_from_page_seo: params.isFromSeoPage ? true : '',
          boost_full_tag: 1,
          exclude_ai: false,
          disp_flg_global: true,
          site: 1,
        };
      } else {
        controlParams = {
          rate: params.rate || 0.56,
          rand: isUndefined(params.rand) ? true : params.rand === 'true',
          pow: params.pow || 1.678,
          param: params.param || 14000,
          p5: params.p5 || 0.9,
          creatorName: params.creatorName,
        };
      }

      if (
        !params.isCategoryPage &&
        serviceType === SERVICE_TYPES.PHOTO_AC &&
        !params.category_id &&
        !params.excludeCategory_id &&
        !params.imageId &&
        !params.creatorName &&
        !params.excludeCreatorName
      ) {
        params.excludeCategory_id = '22,23';
      }
      request = {
        method: 'GET',
        url: `${config.apiUrl.getAPISearchByService(serviceType)}`,
        params: {
          ...params,
          page: params.page || 1,
          size: params.size,
          shape: params.shape,
          // keyword: params.keyword,
          keyword: params?.keyword?.toLowerCase() || '',
          excludeKeyword: params.excludeKeyword,
          categoriesId: params.category_id,
          excludeCategoriesId: params.excludeCategory_id,
          creatorName: params.creatorName,
          excludeCreatorName: params.excludeCreatorName,
          color: params.color === 'all' ? undefined : params.color,
          people_count: params.modelCount,
          ages: params.modelAge,
          // fileType: params.fileType,
          extraLicense: params.license,
          format: params.imageType,
          imageSize: params.imageType,
          max_results: params.per_page || 100,
          language: params.language,
          languageTag: params.language,
          isCategoryPage: params.isCategoryPage,
          is_tag: params.is_tag,
          service_type: serviceType,
          ...controlParams,
          is_search_personalize: params.is_search_personalize,
          ...modelRealeasParam,
          translate: true,
          db: 'on',
          boost_full_tag:
            serviceType === SERVICE_TYPES.ILLUST_AC ||
            serviceType === SERVICE_TYPES.PHOTO_AC
              ? 1
              : 0,
        },
      };

      if (!params.isFromSearchHeader) {
        request.params.is_tag = true;
      } else if (params.token) {
        request.headers = {
          Authorization: params.token,
        };
      }
    } else {
      // Search by image
      request = {
        method: 'GET',
        url: `${config.apiUrl.apiSearchImageService}`,
        params: {
          service_type: params.serviceType || config.serviceType,
          image_token: params.image_token,
          token: params.token,
          language: params.language,
        },
      };
    }
    request.params.country = 'oversea';
    request.params.oversea = true;

    return this.axios(request);
  };

  getSuggestKeyword({ keyword, language = 'en' }) {
    const request = {
      method: 'GET',
      url: `${config.apiUrl.apiSearch}/get-related-word`,
      params: {
        keyword,
        language,
      },
    };

    return axios(request);
  }
}
